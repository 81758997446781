export default [
  {
    key: 'gameName',
    label: 'field.game_name',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'gameAccount',
    selectionKey: 'id',
    selectionLabel: 'displayOption',
    cols: 12,
  },
  {
    key: 'duration',
    label: 'field.duration',
    rules: 'required',
    type: 'text',
    cols: 2,
  },
  {
    key: 'isBan',
    label: 'status.is_ban',
    type: 'checkbox',
    cols: 12,
  },
]
